<template>
    <div style="text-align: left;background: #FFFFFF;">
        <el-card>
            <!-- <h2 class="zdtitle">账单明细</h2> -->
            <div class="df-jc-s-b">
                <div @click="$oucy.back()" class="m-b-20 pointer">&lt;返回</div>
                <!-- <div>
        <el-date-picker v-model="value1" type="daterange" range-separator="至" size="mini" start-placeholder="开始日期" end-placeholder="结束日期">
        </el-date-picker>
        <el-button size="mini" type="primary">查询</el-button>
        <el-button size="mini" type="warning">积分兑换</el-button>
    </div> -->
            </div>
            <el-table :data="prlductData.content" style="width: 100%" class="m-t-10" v-if="prlductData.content">
                <el-table-column prop="date" label="信息记录" width="150px">
                    <!-- hisActionType   变动类型：0签到增加 1兑换礼品扣除 -->
                    <div slot-scope="scope">
                        {{hisActionTypes[scope.row.hisActionType]}}
                    </div>
                </el-table-column>
                <el-table-column prop="hisValue" label="积分">
                    <div slot-scope="scope">
                        {{scope.row.hisValue}}
                    </div>
                </el-table-column>
                <el-table-column prop="hisTime" label="获取时间" width="160px"></el-table-column>
            </el-table>
            <el-pagination class="zdfy" v-if="prlductData" background layout="sizes, prev, pager, next, jumper, ->, total, slot" :page-size="prlductData.size" :total="prlductData.totalElements" @size-change="sizeChange" @current-change="currentChange" @prev-click="currentChange" @next-click="currentChange">
            </el-pagination>
        </el-card>
    </div>
    </div>
</template>
<script>
import oucy from "@/util/oucyUtil";

import { localSet, localGet, localDel } from "@/store/store"
import { sign } from "@/service"
export default {
    name: "EditUser",
    components: {},
    data() {
        return {
            commissionType: null,
            value1: null,
            prlductData: {},
            limit: 10,
            start: 0,
            hisActionTypes: ['签到增加', '兑换礼品扣除']
        }
    },
    mounted() {
        this.getAllUserSignInfo()
    },
    methods: {
        setType(v) {
            if (this.commissionType == v) return
            this.commissionType = v
            this.prlductData = {}
            this.limit = 10
            this.start = 0
            this.getAllUserSignInfo(v)

        },

        sizeChange: function(limit) {
            const me = this;
            me.limit = limit;
            // me.getAllUserSignInfo();
            this.getAllUserSignInfo()
        },
        currentChange: function(current) {
            const me = this;
            me.start = current - 1;
            // me.getAllUserSignInfo();
            this.getAllUserSignInfo()
        },

        // 明细
        getAllUserSignInfo() {
            sign.getAllUserSignInfo({
                limit: this.limit,
                start: this.start,
                commissionType: this.commissionType
            }).then(res => {
                if (res && res.content) {
                    for (let v of res.content) {

                        if (v.commissionType == '1002') {
                            if (v.realName) {
                                v.hade = v.realName.detailAvatar
                                v.naem = v.realName.detailNickname
                                v.Phone = v.realName.userPhoneNumber
                            }
                        } else
                        if (v.commissionType == '1006') {
                            if (v.cardEnterpriseBasic) {
                                v.hade = v.cardEnterpriseBasic.enterpriseAvata
                                v.naem = v.cardEnterpriseBasic.enterpriseName
                                v.Phone = v.cardEnterpriseBasic.cardPhone || '-'
                            }
                        } else
                        if (v.commissionType == '1007') {
                            if (v.cardPersonBasic) {
                                v.hade = v.cardPersonBasic.cardPic
                                v.naem = v.cardPersonBasic.cardName
                                v.Phone = v.cardPersonBasic.cardPhone || '-'
                            }
                        } else
                        if (v.commissionType == '1010') {
                            if (v.enterpriseInfoBasic) {
                                v.hade = v.enterpriseInfoBasic.enterpriseAvata
                                v.naem = v.enterpriseInfoBasic.enterpriseName
                                v.Phone = v.enterpriseInfoBasic.enterprisePhone || '-'
                            }
                        } else
                        if (v.commissionType == '1012') {
                            if (v.enterpriseShopLevelVip) {
                                v.hade = v.enterpriseShopLevelVip.enterpriseAvata
                                v.naem = v.enterpriseShopLevelVip.enterpriseShopName
                                v.Phone = v.enterpriseShopLevelVip.enterprisePhone || '-'
                            }
                        } else
                        if (v.commissionType == '1013') {
                            if (v.enterpriseShopLevelMargin) {
                                v.hade = v.enterpriseShopLevelMargin.enterpriseAvata
                                v.naem = v.enterpriseShopLevelMargin.enterpriseShopName
                                v.Phone = v.enterpriseShopLevelMargin.enterprisePhone || '-'
                            }
                        }

                    }

                }
                this.prlductData = res
            })
        },

    }
}
</script>
<style scoped lang="less">
.payimg {
    display: flex;
    align-content: center;
}

.zdtitle {
    padding-bottom: 15px;
}

.zdfy {
    padding-top: 20px;
}

.head {
    width: 32px;
    height: 32px;
    border-radius: 32px;
}

.c-67973D {
    color: #67973D;
}

.c-2090FF {
    color: #2090FF;
}
</style>